<template>
  <div class="page">
    <div class="header">
      <div class="wrap">
        <div class="inner">
          <div class="logo">
            <img v-if="$route.fullPath.includes('/policy')" src="@/assets/logoZcxx.png" alt="" class="logo-img" />
          </div>
          <div class="nav">
            <a-menu v-if="!isHome" @click="handleItem" :selectable="false" theme="dark" class="nav-menu"
              @select="handleChangeMenu" v-model:selectedKeys="activeKey" mode="horizontal" size="large">
              <template v-for="item in menus" :key="item.id">
                <a-menu-item :key="item.url" v-if="!item.sub_menu.length">{{ item.mc }}</a-menu-item>
              </template>
            </a-menu>
          </div>
          <div class="btns" v-if="!userinfo">
            <div class="label" @click="toLogin">
              <img src="@/assets/icons/sign_login.png" alt="" class="user">
              <div class="username">注册/登录</div>
            </div>
          </div>
          <div class="btns" v-else>
            <div class="label">
              <img src="@/assets/prevBtn.png" alt="" class="user">
              <div class="username" @click="toWork">工作台|</div>
              <div class="username" v-if="userinfo?.phone">{{ userinfo?.phone.substring(0, 3) + '****' +
                userinfo?.phone.substring(7) }}</div>
            </div>
            <div class="btn" @click="exit">
              <img src="@/assets/icons/out.png" alt="退出登录" class="icon">
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="subheader">

    </div>
    <div class="main">
      <div class="content">
        <router-view />
        <div class="footer">
          <Footer />
        </div>
      </div>
    </div>

  </div>
</template>
<script>
import { mapActions, mapMutations, mapState } from 'vuex';
import Footer from './components/footer.vue';
const menus = [
  {
    mc: "首页",
    url: "/prev/preview",
    sub_menu: []
  },
  {
    mc: "政策信息",
    url: "/prev/policy",
    sub_menu: [],
  },
  {
    mc: "知识产权",
    url: "/work/zxcq",
    sub_menu: [],
  },
  {
    mc: "产业技术",
    url: "/work/zyjs",
    sub_menu: [],
  },
  {
    mc: "高端人才",
    url: "/work/gdrc",
    sub_menu: [],
  },
  {
    mc: "政务数据",
    url: "/work/zwsj",
    sub_menu: [],
  },
];

export default {
  data() {
    return {
      activeKey: [],
      menus: [],
    };
  },
  created() {
    this.getMenus();
    // console.log(this.$route.fullPath)
    // this.activeKey.push(this.$route.fullPath)
  },
  watch:{
    $route:{
      handler(val) {
        this.activeKey[0] = val.fullPath;
      },
      immediate:true
    }
  },
  computed: {
    ...mapState(["userinfo"]),
   
    isHome() {
      return this.$route.fullPath.includes('/preview');
    }
  },
  methods: {
    ...mapMutations(["setLogoUrl", 'setUserinfo']),
    ...mapActions(['getUserinfo']),
    toLogin() {
      this.$router.push('/login')
    },
    toWork() {
      this.$router.push('/work/home')
    },
    handleItem({ key }) {
      this.$router.push(key);
      // console.log(item, key);
    },
    async getMenus() {
      // const { data: menus } = await service.get_my_menu({ system_id: 20 });

      this.menus = menus;
      // this.activeKey = [menus?.[0]?.url];
    },
    exit() {
      window.sessionStorage.removeItem("token");
      this.setUserinfo(['userinfo', null]);
      this.$router.replace("/prev/preview");
    }
  },
  components: { Footer }
}
</script>

<style lang="less" scoped>
.page {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  flex-direction: column;
  overflow: hidden;

  // .footer {
  //   position: absolute;
  //   left:0px;
  //   width:100%;
  //   bottom:0px;
  // }

  .header {
    flex: 0 0 auto;

    .wrap {
      height: 68px;
      // background: #fff;
      background: #003466;
      box-shadow: 0 0 16px rgba(51, 135, 255, .12);

      .inner {
        width: 1400px;
        margin: auto;
        height: 68px;
        display: flex;
        align-items: center;
        justify-content: space-between;

        .logo {
          height: 100%;

          .logo-img {
            height: 100%;
            width: auto;
            vertical-align: top;
          }


        }

        .nav {
          flex: 1;
          height: 100%;
          display: flex;
          align-items: flex-end;
          justify-content: center;

        }

        .btns {
          display: flex;
          align-items: center;
          justify-content: flex-end;

          .btn {
            width: 32px;
            height: 32px;
            margin-left: 16px;
            display: flex;
            align-items: center;
            justify-content: center;
            cursor: pointer;

            .icon {
              width: 100%;
              // height:100%;
              height: auto;
              vertical-align: top;
            }
          }

          .label {
            background: @primary-color;
            // border-radius: @border-radius;
            border-radius: 10px;
            color: #fff;
            display: flex;
            align-items: center;
            padding: 0 12px;
            height: 34px;
            cursor: pointer;

            .user {
              width: 22px;
              height: 22px;
              margin-right: 6px;
            }

            .username {
              font-size: 20px;
              letter-spacing: 1px;
              color: #fff;
            }
          }
        }
      }
    }
  }

  .main {
    flex: 1;
    overflow: auto;
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    background: #fff;
    // padding-bottom: 168px;

    .content {
      width: 100%;
    }

    .slide {
      width: 278px;
      padding: 24px;
    }
  }
}
</style>